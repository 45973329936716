<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="link_tab(item)">
          {{ item.content }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="text-center">
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="desserts"
        class="elevation-1 mx-auto mt-5"
      >
        <template v-slot:item.course_title="{ item }">
          <span>{{ item.course_title ? item.course_title : "freetalk" }}</span>
        </template>
        <template v-slot:item.lesson_title="{ item }">
          <span> {{ item.lesson_title ? item.lesson_title : "freetalk" }}</span>
        </template>
        <template v-slot:item.active_flg="{ item }">
          <span v-html="convertStatusInText(item)"></span>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_HISTORY_BOOK_LESSON } from "@/store/user.module";

export default {
  name: "OneOneCourse",
  data: () => {
    return {
      tab: 0,
      items: [
        {
          content: "Chi tiết học viên",
          link: "/user/student/",
          path: "info"
        },
        {
          content: "Các khóa đã học",
          link: "/user/student/",
          path: "course"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/student/",
          path: "OneOneCourse"
        },
        {
          content: "Các bài thi",
          link: "/user/student/",
          path: "takeExam"
        }
      ],
      headers: [
        {
          text: "Thời gian",
          align: "center",
          value: "date_time_start",
          class: "table-history-header-title",
          divider: true
        },
        {
          text: "Khóa học",
          align: "center",
          value: "course_title",
          class: "table-history-header-title",
          divider: true
        },
        {
          text: "Bài học",
          align: "center",
          value: "lesson_title",
          class: "table-history-header-title",
          divider: true
        },
        {
          text: "Giáo viên",
          align: "center",
          value: "name_teacher",
          class: "table-history-header-title",
          divider: true
        },
        {
          text: "Trạng thái",
          align: "center",
          value: "active_flg",
          class: "table-history-header-title",
          divider: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      desserts: "listLessonStudents"
    })
  },
  created() {
    this.$store.dispatch(GET_HISTORY_BOOK_LESSON, {
      user_id: this.$route.params.id
    });
  },
  methods: {
    link_tab(link) {
      return link.link + this.$route.params.id + "/" + link.path;
    },
    convertStatusInText(item) {
      if (item.status_cancel) {
        return `<span class="red--text">Đã hủy</span>`;
      }
      switch (item.active_flg) {
        case 0:
          return `<span class="blue--text">Chưa  học</span>`;
        case 1:
          return `<span class="green--text">Đang học</span>`;
        case 2:
          return "Kết thúc";
        default:
          return "";
      }
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  width: 90% !important;
}
</style>
